<template>
  <div class="doctorDetails flex flex-col space-y-8">
    <vs-card class="mainHeader">
        <div slot="header" class="flex justify-end w-full">
        <!-- <h4>Nurse Details</h4> -->
        <!-- <h4
          style="color: rgba(var(--vs-primary), 1); cursor: pointer;"
          @click="showProfileApprovalView"
          v-if="
            $store.state.AppActiveUser.userType === 'superAdmin' &&
              nurseData.profileUpdated
          "
        >Profile update request. View to approve</h4>

        <h4
          style="color: rgba(var(--vs-primary), 1); cursor: pointer;"
          @click="showProfileApprovalView"
          v-if="
            $store.state.AppActiveUser.userType === 'admin' &&
              nurseData.profileUpdated
          "
        >Profile update request. View to approve</h4>-->
          <strong
            v-if="
              ($store.state.AppActiveUser.userType === 'admin' ||
                $store.state.AppActiveUser.userType === 'superAdmin') &&
              nurseData.profileUpdated
            "
            style="display: block"
            >Profile update request. Review to approve / reject</strong
          >
          <div class="lg:hidden flex items-center space-x-2 w-full">
            <vs-button
              v-if="
                ($store.state.AppActiveUser.userType === 'admin' ||
                  $store.state.AppActiveUser.userType === 'superAdmin') &&
                nurseData.profileUpdated
              "
              @click="showProfileApprovalView"
              class="flex-1"
              >Review</vs-button
            >

            <vs-button @click="getAccountLogs" class="bg-green-light text-primary hover:text-primary" type="filled"> View Logs </vs-button>
            <vs-button @click="saveData" class="flex-1">Save Details</vs-button>

            <!-- <vs-button color="danger" @click="popUpDelete = true" class="flex-1">Delete</vs-button> -->
            <vs-button
              v-if="check_has_permission('changeNursePassword')"
              @click="resetPasswordHandler"
              class="flex-1 truncate"
              >Change Password</vs-button
            >
          </div>
          <div class="hidden lg:block w-full">
                <div
                  v-if="
                    ($store.state.AppActiveUser.userType === 'admin' ||
                      $store.state.AppActiveUser.userType === 'superAdmin') &&
                    nurseData.profileUpdated
                  "
                >
                  <vs-button @click="showProfileApprovalView" class="flex-1"
                    >Review</vs-button
                  >
                </div>
              <div class="flex items-end justify-end space-x-2">
                <vs-button @click="getAccountLogs" class="bg-green-light text-primary hover:text-primary" type="filled"> View Logs </vs-button>
                <vs-button @click="saveData">Save Details</vs-button>
                <!-- <vs-button
                  color="danger"
                  v-if="(canDelete = true)"
                  @click="popUpDelete = true"
                  class="flex-1"
                  >Delete</vs-button>-->
                <vs-button
                  v-if="check_has_permission('changeNursePassword')"
                  @click="resetPasswordHandler"
                  >Change Password</vs-button
                >
              </div>
              <vs-popup :active.sync="popUpDelete" title="Delete Confirmation">
                <p align="center">Are you sure you want to delete this user?</p>
                <br />
                <vs-row
                  vs-align="flex-start"
                  vs-type="flex"
                  vs-justify="left"
                  vs-w="12"
                  vs-mt="6"
                >
                  <vs-col vs-type="flex" vs-align="left" vs-w="6">
                    <div class="w-full" align="right">
                      <vs-button
                        size="lg"
                        color="primary"
                        @click="deleteNurseHandler"
                        >Yes, delete</vs-button
                      >
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-align="left" vs-w="6">
                    <div class="w-full">
                      <vs-button
                        size="lg"
                        color="danger"
                        @click="popUpDelete = false"
                        >Cancel</vs-button
                      >
                    </div>
                  </vs-col>
                </vs-row>
              </vs-popup>
          </div>
      </div>

      <form>
        <div
          id="profileImageUpload"
          class="vs-con-loading__container w-full lg:w-2/12 flex flex-col items-center lg:items-start mb-6 mt-2"
        >
          <div class="flex items-center">
            <div
              style="cursor: pointer"
              @click="open_in_new_tab(nurseData.profileImage)"
              class="img-wrapper"
              v-if="nurseData.profileImage"
            >
              <img :src="nurseData.profileImage" class="img-fluid" alt />
            </div>
            <!-- <vs-avatar
                size="110px"
                :src="nurseData.profileImage"
                v-if="nurseData.profileImage"
              />-->
            <vs-avatar
              style="cursor: pointer"
              @click="open_in_new_tab(defaultPP)"
              size="110px"
              :src="defaultPP"
              v-else
            />
          </div>
          <label
            class="image-upload block text-primary font-medium text-center text-base mt-2">
            Change Photo
            <input
              type="file"
              accept="image/*"
              @change="uploadImage($event, 'profileImageUpload')"
              id="file-input"
              hidden
            />
              </label>
        </div>
        <div class="w-full lg:w-auto flex-1 grid grid-cols sm:grid-cols-2 gap-6 lg:gap-4 mt-4 lg:mt-0">
          <div class="w-full">
              <vs-input
                :success="
                  !errors.first('firstName') && nurseData.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('firstName') ? true : false"
                :danger-text="errors.first('firstName')"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                v-model="nurseData.firstName"
              />
          </div>

          <div class="w-full">
              <vs-input
                :success="
                  !errors.first('lastName') && nurseData.lastName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('lastName') ? true : false"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                class="w-full"
                :danger-text="errors.first('lastName')"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                v-model="nurseData.lastName"
              />
          </div>

          <div class="w-full">
              <vs-input
                :success="
                  !errors.first('contactNumber') &&
                  nurseData.contactNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('contactNumber') ? true : false"
                v-validate="'required'"
                name="contactNumber"
                :danger-text="errors.first('contactNumber')"
                data-vv-as="contact number"
                label="Contact Number"
                class="w-full"
                icon-no-border
                icon="icon icon-phone"
                icon-pack="feather"
                v-model="nurseData.contactNumber"
              />
          </div>

          <div class="w-full">
              <label class="vs-input--label">Date Of Birth</label>
              <datepicker
                format="dd/MM/yyyy"
                v-model="nurseData.dateOfBirth"
                :input-class="{
                  'is-true':
                    !errors.has('dateOfBirth') && nurseData.dateOfBirth,
                  'is-danger': errors.has('dateOfBirth'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="nurseData.dateOfBirth"
                    class="bg-color-done input-icon-validate vs-input--icon-validate"
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('dateOfBirth')"
                    class="bg-color-error input-icon-validate vs-input--icon-validate"
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">
                {{ errors.first("dateOfBirth") }}
              </span>
              <input
                type="hidden"
                data-vv-as="Date of birth"
                data-vv-validate-on="change"
                name="dateOfBirth"
                v-validate="{ required: isRequired }"
                v-model="nurseData.dateOfBirth"
              />
          </div>

          <div class="w-full relative">
            <!--<label for>Email</label>
            <p style="margin-right: 5px; font-size: 1rem">
              {{ nurseData.email }}
            </p>-->

            <vx-tooltip
                v-if="nurseData.emailVerifiedAt"
                text="Verified"
                class="absolute top-0 right-0"
              >
                <feather-icon
                  style="color: rgba(var(--vs-primary), 1) !important"
                  icon="CheckCircleIcon"
                  svgClasses="h-5 w-5"
                  class="mr-2 cursor-pointer"
                ></feather-icon>
              </vx-tooltip>
              <vx-tooltip v-else text="Unverified" class="absolute top-0 right-0">
                <feather-icon
                  style="color: rgba(var(--vs-danger), 1) !important"
                  icon="AlertCircleIcon"
                  svgClasses="h-5 w-5"
                  class="mr-2 cursor-pointer"
                ></feather-icon>
              </vx-tooltip>
              <VerifyEmail
                @onSuccessfullyVerified="onSuccessfullyVerified"
                v-if="!nurseData.emailVerifiedAt"
                :userId="nurseId"
              />

            <vs-row vs-type="flex" vs-align="left" class="w-full">
              <vs-input
                :success="!errors.first('email') && nurseData.email != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                class="w-full"
                :danger-text="errors.first('email')"
                v-model="nurseData.email"
              />
            </vs-row>
          </div>

          <div class="flex items-end justify-between w-full">

            <div class="w-full flex items-center space-x-2 my-3">
              <label for>Disable User</label>
              <vs-switch
                color="danger"
                @click="changeStatus(userStatus)"
                class="m-2"
                v-model="userStatus"
              />
              <div v-if="statusChangedClick">
                <span style="margin-right: 5px">Processing</span>
                <img
                  src="../../../../public/loader.gif"
                  style="width: 100%; height: 100%"
                />
              </div>
            </div>

            <div class="w-full flex items-center space-x-2 my-3" v-if="adminApprovalVar == true">
              <label for>Admin Approval</label>
              <vs-switch
                @click="changeAdminApproval(adminStatus)"
                class="m-2"
                v-model="adminStatus"
              />
              <div v-if="adminApprovalChangeClick">
                <span style="margin-right: 5px">Processing</span>
                <img
                  src="../../../../public/loader.gif"
                  style="width: 100%; height: 100%"
                />
              </div>
            </div>

          </div>

        </div>
      </form>
    </vs-card>
    <vs-card v-if="region === 'AU'">
      <div slot="header">
        <h4>AHPRA Details</h4>
      </div>

      <div class="grid grid-cols sm:grid-cols-2 gap-6 lg:gap-4">
          <vs-input
            :success="
              !errors.first('ahpraRegistrationNumber') &&
              nurseData.ahpraRegistrationNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('ahpraRegistrationNumber') ? true : false"
            v-validate="'required'"
            name="ahpraRegistrationNumber"
            :danger-text="errors.first('ahpraRegistrationNumber')"
            data-vv-as="AHPRA Registration Number"
            label="AHPRA Registration Number"
            class="w-full"
            icon-no-border
            icon="icon icon-briefcase"
            icon-pack="feather"
            v-model="nurseData.ahpraRegistrationNumber"
          />
          <div class="w-full">
            <label class="vs-input--label">AHPRA Expiry date</label>
            <datepicker
              format="dd/MM/yyyy"
              :disabled-dates="disabledDates"
              v-model="nurseData.ahpraExiryDate"
              :input-class="{
                'is-true':
                  !errors.has('ahpraExiryDate') && nurseData.ahpraExiryDate,
                'is-danger': errors.has('ahpraExiryDate'),
              }"
            >
              <template slot="afterDateInput">
                <span
                  v-if="nurseData.ahpraExiryDate"
                  class="bg-color-done input-icon-validate vs-input--icon-validate"
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >done</i
                  >
                </span>
                <span
                  v-if="errors.has('ahpraExiryDate')"
                  class="bg-color-error input-icon-validate vs-input--icon-validate"
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >error</i
                  >
                </span>
              </template>
            </datepicker>
            <span class="text-danger" style="font-size: 0.75em">
              {{ errors.first("ahpraExiryDate") }}
            </span>
            <input
              type="hidden"
              data-vv-as="APHRA expiry date"
              data-vv-validate-on="change"
              name="ahpraExiryDate"
              v-validate="'required'"
              v-model="nurseData.ahpraExiryDate"
            />
          </div>
          <div id="aphraUpload" class="vs-con-loading__container w-full">
            <div>
              <label class="vs-input--label">AHPRA Certificate</label>
              <div v-if="nurseData.ahpraCertificate">
                <div
                  v-if="
                    determineFileType(nurseData.ahpraCertificate) === 'pdf'
                  "
                >
                  <a :href="nurseData.ahpraCertificate" target="_blank" class="text-md"
                    >Click here to view file</a
                  >
                </div>
                <div
                  style="cursor: pointer"
                  @click="open_in_new_tab(nurseData.ahpraCertificate)"
                  v-else
                  class="large-square-img-full-width-wrapper"
                >
                  <img :src="nurseData.ahpraCertificate" class="img-fluid" />
                </div>
                <!-- <div v-else style="width:100%;">
              <img :src="nurseData.ahpraCertificate" width="100%" />
            </div>-->
              </div>
              <div>
                <label
                  class="image-upload block w-full text-primary text-base text-center rounded-lg border border-solid border-primary py-2"
                >
                  Change Certificate
                  <input
                    type="file"
                    @change="uploadImage($event, 'aphraUpload')"
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
    </vs-card>
    <vs-card v-if="!$isUSRegion()">
      <div slot="header">
        <h4>Insurance Details</h4>
      </div>
      <div class="grid grid-cols sm:grid-cols-2 gap-6 lg:gap-4p">
          <vs-input
            :success="
              !errors.first('indemnityInsuranceProvider') &&
              nurseData.indemnityInsuranceProvider != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="
              errors.first('indemnityInsuranceProvider') ? true : false
            "
            v-validate="'required'"
            :danger-text="errors.first('indemnityInsuranceProvider')"
            name="indemnityInsuranceProvider"
            data-vv-as="indeminity insurance provider"
            label="Indemnity Insurance Provider"
            class="w-full"
            icon-no-border
            icon="icon icon-file"
            icon-pack="feather"
            v-model="nurseData.indemnityInsuranceProvider"
          />
          <vs-input
            :success="
              !errors.first('indemnityInsuranceNumber') &&
              nurseData.indemnityInsuranceNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="
              errors.first('indemnityInsuranceNumber') ? true : false
            "
            v-validate="'required'"
            name="indemnityInsuranceNumber"
            :danger-text="errors.first('indemnityInsuranceNumber')"
            data-vv-as="indemnity insurance number"
            label="Indemnity Insurance number"
            class="w-full"
            icon-no-border
            icon="icon icon-file"
            icon-pack="feather"
            v-model="nurseData.indemnityInsuranceNumber"
          />
          <div class="w-full">
              <label class="vs-input--label">Indemnity Insurance Expirys</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="nurseData.indemnityInsuranceExpiry"
                name="indemnityInsuranceExpiry"
                :input-class="{
                  'is-true':
                    !errors.first('indemnityInsuranceExpiry') &&
                    nurseData.indemnityInsuranceExpiry,
                  'is-danger': errors.first('indemnityInsuranceExpiry'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="nurseData.indemnityInsuranceExpiry"
                    class="bg-color-done input-icon-validate vs-input--icon-validate"
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('indemnityInsuranceExpiry')"
                    class="bg-color-error input-icon-validate vs-input--icon-validate"
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger">
                {{ errors.first("indemnityInsuranceExpiry") }}
              </span>
              <input
                type="hidden"
                data-vv-as="Indemnity Insurance Expiry"
                data-vv-validate-on="change"
                name="indemnityInsuranceExpiry"
                v-validate="'required'"
                v-model="nurseData.indemnityInsuranceExpiry"
              />
          </div>
          <div></div>
          <div id="insuranceUpload">
            <label class="vs-input--label">Insurance Certificate</label>
            <div v-if="nurseData.insuranceCertificate">
              <div
                v-if="
                  determineFileType(nurseData.insuranceCertificate) ===
                  'pdf'
                ">
                <a :href="nurseData.insuranceCertificate" target="_blank" class="text-md"
                  >Click here to view file</a
                >
              </div>
              <div
                class="cursor-pointer"
                @click="open_in_new_tab(nurseData.insuranceCertificate)"
                v-else
              >
                <img
                  :src="nurseData.insuranceCertificate"
                  class="img-fluid"
                />
              </div>
              <!-- <div v-else style="width:100%;">
            <img :src="nurseData.insuranceCertificate" width="100%" />
          </div>-->
            </div>
            <div>
              <label
              class="image-upload block w-full text-primary text-base text-center rounded-lg border border-solid border-primary py-2"
            >
                Change Certificate
                <input
                  type="file"
                  @change="uploadImage($event, 'insuranceUpload')"
                  id="file-input"
                  hidden
                />
              </label>
            </div>
          </div>
        </div>
    </vs-card>
    <vs-popup title="Reset Password" :active.sync="resetPassword">
      <form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
          class="p-3"
        >
          <vs-col  class="p-3">
            <div class="w-full">
              <vs-input
                :success="
                  !errors.first('resetPassword.newPassword') && password != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('resetPassword.newPassword') ? true : false
                "
                name="newPassword"
                :danger-text="errors.first('resetPassword.newPassword')"
                label="New Password"
                class="w-full"
                v-validate="{
                  required: true,
                  min: 8,
                  regex:
                    /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                }"
                data-vv-as="new password"
                data-vv-scope="resetPassword"
                ref="password"
                icon-no-border
                icon-pack="feather"
                v-model="password"
                :icon="passwordIcon"
                :type="passwordType"
                @click.native="showHidePassword($event)"
              />
            </div>
          </vs-col>
          <vs-col  class="p-3">
            <div class="w-full">
              <vs-input
                label="Confirm Password"
                :success="
                  !errors.first('resetPassword.confirmPassword') &&
                  confirmPassword != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('resetPassword.confirmPassword') ? true : false
                "
                name="confirmPassword"
                :danger-text="errors.first('resetPassword.confirmPassword')"
                v-validate="'required|confirmed:password'"
                data-vv-as="confirm password"
                data-vv-scope="resetPassword"
                class="w-full"
                icon-no-border
                icon-pack="feather"
                v-model="confirmPassword"
                :icon="confirmPasswordIcon"
                :type="confirmPasswordType"
                @click.native="showHideConfirmPassword($event)"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="7" class="p-3">
            <div class="w-full">
              <vs-button
                size="lg"
                @click="changePassword"
                icon-pack="feather"
                class="mr-2"
                >Reset Password</vs-button
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="5" class="p-3">
            <div class="w-full" align="right">
              <vs-button
                @click="resetPassword = false"
                color="danger"
                icon-pack="feather"
                >Cancel</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </form>
    </vs-popup>
    <!-- <vs-card v-if="check_has_permission('viewNurseTreatmentHistory')">
      <template v-slot:header>
        <h4>Nurse Treatment History</h4>
      </template>
      <NurseTreatmentHistory :nurseDetails="nurseData"></NurseTreatmentHistory>
    </vs-card> -->
    <LogsSidebar :activity_logs="activityLogs" ref="logsSidebar" />
  </div>
</template>

<script>
import axios from "@/axios";
import { mapActions } from "vuex";
import NurseTreatmentHistory from "./NurseTreatmentHistory";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import VerifyEmail from "@/views/general-component/VerifyEmail";
import moment from "moment";
import { updatedFieldChecker } from "../../../utils/updatedFieldChecker";
import LogsSidebar from "@/views/components/account-creation-logs/logs.vue";

const dict = {
  custom: {
    firstName: {
      required: "Please enter your first name",
    },
    lastName: {
      required: "Please enter your last name",
    },
    email: {
      required: "Please enter valid email address",
    },
    contactNumber: {
      required: "Please enter your contact number",
    },
    ahpraRegistrationNumber: {
      required: "Please enter valid AHPRA registration number",
    },
    ahpraExiryDate: {
      required: "Please enter valid AHPRA Expiry Date",
    },
    indemnityInsuranceProvider: {
      required: "Please enter valid indemnity insurance name",
    },
    indemnityInsuranceNumber: {
      required: "Please enter valid indemnity insurance number",
    },
    indemnityInsuranceExpiry: {
      required: "Please enter valid indemnity insurance expiry date",
    },
    newPassword: {
      required: "Please enter your password",
      min: "Password must be at least 8 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: " The confirmation password do not match",
    },
  },
};
Validator.localize("en", dict);
export default {
  name: "nurse-detail",
  components: {
    Datepicker,
    NurseTreatmentHistory,
    VerifyEmail,
    LogsSidebar
  },
  props: {
    showTreatmentHistory: {
      type: Boolean,
      default: true,
    },
    fetchClinicsBy: {
      type: String,
      default: "franchise",
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    adminApprovalVar: {
      type: Boolean,
      default: false,
    },
    backRoute: {
      type: String,
      default: "/franchise/nurses",
    },
  },
  data: () => ({
    isRequired: true,
    activeUserType: "",
    defaultPP: require("@/assets/images/profile/default.png"),
    statusChangedClick: false,
    adminApprovalChangeClick: false,
    resetPasswordPopup: false,
    nurseId: "",
    password: "",
    confirmPassword: "",
    validationKeys: {
      showRestrictionImposeError: false,
      showAggreementOnAhpraRegistrationChangedError: false,
      showAggreementOnIndemnityRegistrationChangedError: false,
      showRnOrEnError: false,
      showToxinTreatmentError: false,
      showFillerTreatmentError: false,
      showHasCompletedHyalaseTraningInLastTwelveMonth: false,
      showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth: false,
      showHasReadClinicalStandardsDocumentsError: false,
      showHasConfirmedDeclarationsError: false,
      showHasImmunityToHepatitisBError: false,
      showCanSendEmailError: false,
    },
    nurseData: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      preferredName: "",
      contactNumber: "",
      ahpraRegistrationNumber: "",
      ahpraExiryDate: "",
      indemnityInsuranceProvider: "",
      indemnityInsuranceNumber: "",
      indemnityInsuranceExpiry: "",
      profileImage: "",
      ahpraCertificate: "",
      insuranceCertificate: "",
      signature: "",
      status: "",
      hasApprovedByAdmin: "",
      nurseCosmeticExperience: "",
      userType: "nurse",
      profileUpdated: false,
      emailVerifiedAt: null,
      clinicName: null,
      instagram: null,
      facebook: null,
      website: null,
      clinicAddress1: null,
      city: null,
      stateOrRegion: null,
      photoId: null,
      restrictionImposed: null,
      aggreementOnAhpraRegistrationChanged: null,
      aggreementOnIndemnityRegistrationChanged: null,
      rnOrEn: null,
      expericedYearInRnOrEn: null,
      areasForRnOrEn: null,
      cosmeticInjectorExperienceYears: null,
      cosmeticInjectableCourses: null,
      trainingCertificate: null,
      additionalTeachingInInjectables: null,
      averageHourInCosmeticPracticePerWeek: null,
      planForAverageHourInCosmeticPracticePerWeek: null,
      numberOfPatientsTreated: null,
      confidentAreasForToxinTreatment: {
        Glabella: false,
        Frontalis: false,
        "Lateral Orbicularis Oculi": false,
        "Lip eversion": false,
        "Lipstick lines": false,
        "Brow Lift": false,
        "Nasalis (bunny lines)": false,
        "LLSAN (gummy smile)": false,
        Mentalis: false,
        DAO: false,
        Masseters: false,
        Hyperhidrosis: false,
        Platysma: false,
      },
      confidentAreasForFillerTreatment: {
        "Lip filler": false,
        Chin: false,
        "Tear Trough": false,
        Cheeks: false,
        Jawline: false,
        "Lip-stick lines": false,
        NLF: false,
        "Marionette Lines": false,
      },
      areasNotConfidentIn: null,
      additionalAreaOfPractice: null,
      cv: null,
      hasCompletedHyalaseTraningInLastTwelveMonth: null,
      hyalaseOrComplicationManagementCertificate: null,
      // hasCompletedBasicLifeSupportTrainingInLastTwelveMonth: null,
      /*blsCertificate: null,
      hasImmunityToHepatitisB: null,
      vaccinationCertificate: null,*/
      hasReadClinicalStandardsDocuments: null,
      hasConfirmedDeclarations: null,
      canSendEmail: null,
      dateOfBirth: "",
      hyalaseTrainingExpirationDate: "",
      // blsTrainingExpirationDate: ""
    },
    clinics: [],
    clinic: "",
    disabledDates: { to: new Date() },
    userStatus: false,
    adminStatus: false,
    popUpDelete: false,
    resetPassword: false,
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
    confirmPasswordType: "password",
    confirmPasswordIcon: "icon icon-eye-off",
    region: process.env.VUE_APP_REGION,
    updater: null,
    oldData: null,
    active: false,
    activityLogs: []
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.nurseData.firstName != "" &&
        this.nurseData.lastName != "" &&
        this.nurseData.contactNumber != "" &&
        this.nurseData.ahpraRegistrationNumber != "" &&
        this.nurseData.ahpraExiryDate != "" &&
        this.nurseData.indemnityInsuranceProvider != "" &&
        this.nurseData.indemnityInsuranceNumber != "" &&
        this.nurseData.indemnityInsuranceExpiry != "" &&
        this.nurseData.ahpraCertificate != "" &&
        this.nurseData.insuranceCertificate != "" &&
        this.nurseData.email != "" &&
        /* this.nurseData.clinicName != null &&
         this.nurseData.city != null &&
         this.nurseData.stateOrRegion != null &&
         this.nurseData.restrictionImposed != null &&
         this.nurseData.aggreementOnAhpraRegistrationChanged != null &&
         this.nurseData.aggreementOnIndemnityRegistrationChanged != null &&
         this.nurseData.rnOrEn != null &&
         this.nurseData.expericedYearInRnOrEn != null &&
         this.nurseData.areasForRnOrEn != null &&
         this.nurseData.cosmeticInjectorExperienceYears != null &&
         this.nurseData.additionalTeachingInInjectables != null &&
         this.nurseData.averageHourInCosmeticPracticePerWeek != null &&
         this.nurseData.planForAverageHourInCosmeticPracticePerWeek != null &&
         this.nurseData.numberOfPatientsTreated != null &&
         this.nurseData.areasNotConfidentIn != null &&
         this.nurseData.additionalAreaOfPractice != null &&
         this.nurseData.cv != null &&
         this.nurseData.hasCompletedHyalaseTraningInLastTwelveMonth != null &&
         this.nurseData.hyalaseOrComplicationManagementCertificate != null &&
         this.nurseData.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth !=
           null &&
         this.nurseData.blsCertificate != null &&
         this.nurseData.hasImmunityToHepatitisB != null &&
         this.nurseData.vaccinationCertificate != null &&
         this.nurseData.hasReadClinicalStandardsDocuments != null &&
         this.nurseData.hasConfirmedDeclarations != null &&
         this.nurseData.canSendEmail != null &&*/
        this.nurseData.dateOfBirth != ""
        /*this.nurseData.hyalaseTrainingExpirationDate != "" &&
        this.nurseData.blsTrainingExpirationDate != ""*/
      );
    },
  },
  methods: {
    ...mapActions("nurse", [
      "fetchNurseDetails",
      "updateNurseDetails",
      "deleteNurse",
      "resetNursePassword",
    ]),
    ...mapActions("franchise", ["fetchClinics"]),
    ...mapActions("appUser", ["getAccountCreationLogs"]),
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async saveData() {
      let isValid = await this.$validator.validateAll(null);

      if (this.nurseData.ahpraCertificate === "" && this.$isAuRegion()) {
        isValid = false;
        this.errors.add({
          field: "aphraCertificate",
          msg: "APHRA Certificate is required",
        });
        this.$vs.notify({
          title: "Failed",
          text: "APHRA Certificate is required!",
          color: "danger",
        });
      } else {
        if (this.region === "AU") {
          this.errors.remove("aphraCertificate");
        }
      }

      if (this.nurseData.insuranceCertificate == "" && !this.$isUSRegion()) {
        isValid = false;
        this.errors.add({
          field: "insuranceCertificate",
          msg: "Insurance Certificate is required",
          // scope: 'basic'
        });
        this.$vs.notify({
          title: "Failed",
          text: "Insurance Certificate is required.!",
          color: "danger",
        });
      } else {
        if (this.region === "AU") {
          this.errors.remove("insuranceCertificate");
        }
      }

      if (
        moment(new Date(this.nurseData.dateOfBirth)).format("YYYY-MM-DD") >
        moment().subtract(18, "years").format("YYYY-MM-DD")
      ) {
        isValid = false;
        this.errors.add({
          field: "dateOfBirth",
          msg: "Your age must be 18 years or above",
        });
      } else {
        this.errors.remove("dateOfBirth");
      }

      // if (
      //   this.$store.state.AppActiveUser.userType !== "superAdmin" &&
      //   this.$store.state.AppActiveUser.userType !== "admin" &&
      //   this.$store.state.AppActiveUser.userType !== "franchise" &&
      //   this.$store.state.AppActiveUser.userType !== "subFranchise" &&
      //   this.$store.state.AppActiveUser.userType !== "clinic" &&
      //   this.$store.state.AppActiveUser.userType !== "subClinic" &&
      //   localStorage.getItem('AdminStagingRole') !== "Org Owner"
      // ) {
      //   if (!this.nurseData.canSendEmail) {
      //     isValid = false;
      //     this.validationKeys.showCanSendEmailError = true;
      //   } else {
      //     this.validationKeys.showCanSendEmailError = false;
      //   }

      //   if (!this.nurseData.hasConfirmedDeclarations) {
      //     isValid = false;
      //     this.validationKeys.showHasConfirmedDeclarationsError = true;
      //   } else {
      //     this.validationKeys.showHasConfirmedDeclarationsError = false;
      //   }

      //   if (!this.nurseData.hasReadClinicalStandardsDocuments) {
      //     isValid = false;
      //     this.validationKeys.showHasReadClinicalStandardsDocumentsError = true;
      //   } else {
      //     this.validationKeys.showHasReadClinicalStandardsDocumentsError = false;
      //   }
      // }

      if (isValid) {
        if (this.adminStatus == true) this.nurseData.hasApprovedByAdmin = true;
        if (this.adminStatus == false)
          this.nurseData.hasApprovedByAdmin = false;
        if (this.userStatus == false) this.nurseData.status = "Active";
        if (this.userStatus == true) {
          this.nurseData.status = "Disabled";
          // this.nurseData.hasApprovedByAdmin = false;
        }

        //this.nurseData.hasApprovedByAdmin = this.adminStatus
        this.$vs.loading();
        this.nurseData.userId = this.nurseData.id,
        this.nurseData.updater = `${this.updater.firstName} ${this.updater.lastName}`,
        this.nurseData.name = `${this.nurseData.firstName} ${this.nurseData.lastName}`,
        this.nurseData.updatedFields = updatedFieldChecker(this.oldData, this.nurseData)

        await this.updateNurseDetails({
          id: this.nurseId,
          data: this.nurseData,
        })
          .then((res) => {
            this.$vs.notify({
              title: "Nurse updated",
              text: "Nurse's details updated.",
              color: "success",
            });
            const user = this.$store.state.AppActiveUser.userRole;
            if (user === "superAdmin") {
              this.$router.push("/super-admin/nurses");
            } else if (user === "admin") {
              this.$router.push("/admin/nurses");
            }
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.notify({
              title: "Failed",
              text: "Failed to update Nurse. Try again!",
              color: "danger",
            });
            isValid = false;
            if (err.status === 422) {
              this.mapServerErrors(this, err, "");
            }
            if (err.status === 417) {
              this.errors.add({
                field: "email",
                msg: err.data.message,
              });
            }
            this.$vs.loading.close();
          });
      } else {
        console.error(this.errors);
      }
    },
    chang(key = "") {
      this.$vs.loading({
        text: "Uploading ...",
        color: '"#3dc9b3"',
        background: "white",
        container: "#" + key + " div div",
      });
    },
    handleError(key = "") {
      this.$vs.loading.close("#" + key + " div div > .con-vs-loading");
      this.$vs.notify({
        title: "Upload Failed",
        text: "Unable to upload file at the moment.",
        color: "danger",
      });
    },
    determineFileType(str) {
      if (str && str.length > 0) {
        var res = str.substring(str.length - 3, str.length);
        return res;
      }
    },
    onSuccessfullyVerified(verifiedAt) {
      this.nurseData.emailVerifiedAt = verifiedAt;
    },
    successUpload(tab, event) {
      let response = JSON.parse(event.target.response);
      if (tab == "a") {
        this.$vs.loading.close("#aphraUpload div div > .con-vs-loading");
        this.nurseData.ahpraCertificate = response.Location;
        this.errors.remove("aphraCertificate");
      }
      if (tab == "i") {
        this.$vs.loading.close("#insuranceUpload div div > .con-vs-loading");
        this.nurseData.insuranceCertificate = response.Location;
        this.errors.remove("insuranceCertificate");
      }

      if (tab == "pp") {
        this.nurseData.profileImage = response.Location;
        this.$vs.loading.close("#profileUpload div div > .con-vs-loading");
      }

      this.$vs.loading.close();
    },
    deleteFile(tab) {
      if (tab == "a") this.nurseData.ahpraCertificate = "";
      if (tab == "i") this.nurseData.insuranceCertificate = "";
      if (tab == "s") this.nurseData.signature = "";
      if (tab == "pp") this.nurseData.profileImage = "";
    },
    async changePassword() {
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetNursePassword({
          id: this.nurseId,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        })
          .then((res) => {
            this.resetPassword = false;
            this.resetPasswordPopup = false;
            this.password = "";
            this.confirmPassword = "";
            this.$validator.reset();
            this.$vs.notify({
              title: "Nurse's password reset",
              text: "Nurse's password reset Successfully.",
              color: "success",
            });
          })
          .catch((err) => {
            this.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger",
            });
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
          scope: form,
        });
      });
    },
    async getNurseDetail(nurseId) {
      await this.fetchNurseDetails(nurseId).then((res) => {
        if (res.data.data.permissions) {
          this.$store.commit(
            "UPDATE_USER_PERMISSION",
            res.data.data.permissions
          );
        }
        this.oldData = res.data.data;
        const nurse = res.data.data;
        this.nurseId = nurse._id;
        this.nurseData = {
          id: nurse._id,
          firstName: nurse.firstName,
          lastName: nurse.lastName,
          preferredName: nurse.preferredName,
          contactNumber: nurse.contactNumber,
          ahpraRegistrationNumber: nurse.ahpraRegistrationNumber,
          ahpraExiryDate: nurse.ahpraExiryDate
            ? this.formatDate(nurse.ahpraExiryDate)
            : null,
          indemnityInsuranceProvider: nurse.indemnityInsuranceProvider,
          indemnityInsuranceNumber: nurse.indemnityInsuranceNumber,
          indemnityInsuranceExpiry: nurse.indemnityInsuranceExpiry
            ? this.formatDate(nurse.indemnityInsuranceExpiry)
            : null,
          profileImage: nurse.profileImage,
          ahpraCertificate: nurse.ahpraCertificate,
          insuranceCertificate: nurse.insuranceCertificate,
          signature: nurse.signature,
          status: nurse.status,
          hasApprovedByAdmin: nurse.hasApprovedByAdmin,
          profileUpdated: nurse.profileUpdated,
          nurseCosmeticExperience: nurse.nurseCosmeticExperience,
          email: nurse.email,
          emailVerifiedAt: nurse.emailVerifiedAt,
          clinicName: nurse.clinicName,
          instagram: nurse.instagram,
          facebook: nurse.facebook,
          website: nurse.website,
          clinicAddress1: nurse.clinicAddress1,
          city: nurse.city,
          stateOrRegion: nurse.stateOrRegion,
          photoId: nurse.photoId,
          restrictionImposed: nurse.restrictionImposed,
          aggreementOnAhpraRegistrationChanged:
            nurse.aggreementOnAhpraRegistrationChanged,
          aggreementOnIndemnityRegistrationChanged:
            nurse.aggreementOnIndemnityRegistrationChanged,
          rnOrEn: nurse.rnOrEn,
          expericedYearInRnOrEn: nurse.expericedYearInRnOrEn,
          areasForRnOrEn: nurse.areasForRnOrEn,
          cosmeticInjectorExperienceYears:
            nurse.cosmeticInjectorExperienceYears,
          cosmeticInjectableCourses: nurse.cosmeticInjectableCourses,
          trainingCertificate: nurse.trainingCertificate,
          additionalTeachingInInjectables:
            nurse.additionalTeachingInInjectables,
          averageHourInCosmeticPracticePerWeek:
            nurse.averageHourInCosmeticPracticePerWeek,
          planForAverageHourInCosmeticPracticePerWeek:
            nurse.planForAverageHourInCosmeticPracticePerWeek,
          numberOfPatientsTreated: nurse.numberOfPatientsTreated,
          confidentAreasForToxinTreatment: nurse.confidentAreasForToxinTreatment
            ? nurse.confidentAreasForToxinTreatment
            : {
                Glabella: false,
                Frontalis: false,
                "Lateral Orbicularis Oculi": false,
                "Lip eversion": false,
                "Lipstick lines": false,
                "Brow Lift": false,
                "Nasalis (bunny lines)": false,
                "LLSAN (gummy smile)": false,
                Mentalis: false,
                DAO: false,
                Masseters: false,
                Hyperhidrosis: false,
                Platysma: false,
              },
          confidentAreasForFillerTreatment:
            nurse.confidentAreasForFillerTreatment
              ? nurse.confidentAreasForFillerTreatment
              : {
                  "Lip filler": false,
                  Chin: false,
                  "Tear Trough": false,
                  Cheeks: false,
                  Jawline: false,
                  "Lip-stick lines": false,
                  NLF: false,
                  "Marionette Lines": false,
                },
          areasNotConfidentIn: nurse.areasNotConfidentIn,
          additionalAreaOfPractice: nurse.additionalAreaOfPractice,
          // cv: nurse.cv,
          hasCompletedHyalaseTraningInLastTwelveMonth:
            nurse.hasCompletedHyalaseTraningInLastTwelveMonth,
          hyalaseOrComplicationManagementCertificate:
            nurse.hyalaseOrComplicationManagementCertificate,
          /*hasCompletedBasicLifeSupportTrainingInLastTwelveMonth:
            nurse.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth,
          blsCertificate: nurse.blsCertificate,
          vaccinationCertificate: nurse.vaccinationCertificate,
          hasImmunityToHepatitisB: nurse.hasImmunityToHepatitisB,*/
          hasReadClinicalStandardsDocuments:
            nurse.hasReadClinicalStandardsDocuments,
          hasConfirmedDeclarations: nurse.hasConfirmedDeclarations,
          canSendEmail: nurse.canSendEmail,
          dateOfBirth: nurse.dateOfBirth
          ? this.formatDate(nurse.dateOfBirth)
            : "",
          hyalaseTrainingExpirationDate: nurse.hyalaseTrainingExpirationDate,
          // blsTrainingExpirationDate: nurse.blsTrainingExpirationDate
        };
        if (this.nurseData.status == "Active") this.userStatus = false;
        else this.userStatus = true;

        this.adminStatus = this.nurseData.hasApprovedByAdmin;
      });
    },
    formatDate(date) {
      var d = new Date(date,'YYYY-M-D');
      if (isNaN(d.getTime())) {
        var momnetDate = moment(date, 'YYYY-M-D'),
        month = "" + (momnetDate.month() + 1), // In moment.js, months are 0-indexed
        day = "" + momnetDate.date(),
        year = momnetDate.year();
      }
      else{
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      }
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
      });
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload", data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            this.nurseData.profileImage = res.data.Location;
          }
          if (key === "aphraUpload") {
            this.nurseData.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            this.nurseData.insuranceCertificate = res.data.Location;
          }
          if (key === "signature") {
            this.nurseData.signature = res.data.Location;
          }
          if (key === "photoIdUpload") {
            this.errors.remove("photoId");
            document.getElementById("photoId").style.borderColor = "#cccccc";
            this.nurseData.photoId = res.data.Location;
          }
          if (key === "trainingCertificateUpload") {
            this.errors.remove("trainingCertificate");
            document.getElementById("trainingCertificate").style.borderColor =
              "#cccccc";
            this.nurseData.trainingCertificate = res.data.Location;
          }
          if (key === "cvUpload") {
            this.errors.remove("cv");
            document.getElementById("cvId").style.borderColor = "#cccccc";
            this.nurseData.cv = res.data.Location;
          }
          if (key === "hyalaseManagenentCertificateUpload") {
            this.errors.remove("hyalaseOrComplicationManagementCertificate");
            document.getElementById(
              "hyalaseManagenentCertificateId"
            ).style.borderColor = "#cccccc";
            this.nurseData.hyalaseOrComplicationManagementCertificate =
              res.data.Location;
          }
          if (key === "blsCertificateUpload") {
            this.errors.remove("blsCertificate");
            document.getElementById("blsCertificateId").style.borderColor =
              "#cccccc";
            this.nurseData.blsCertificate = res.data.Location;
          }
          if (key === "vaccinationCertificateUpload") {
            this.errors.remove("vaccinationCertificate");
            document.getElementById(
              "vaccinationCertificateId"
            ).style.borderColor = "#cccccc";
            this.nurseData.vaccinationCertificate = res.data.Location;
          }
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    deleteNurseHandler() {
      this.popUpDelete = false;
      let user = this.$store.state.AppActiveUser.userRole;
      let id = this.nurseId;
      this.deleteNurse(id).then((res) => {
        this.$vs.notify({
          title: "Nurse deleted",
          text: "Nurse deleted Successfully.",
          color: "success",
        });
        if (user === "superAdmin") {
          this.$router.push("/super-admin/nurses");
        } else {
          this.$router.push("/" + user + "/nurses");
        }
      });
    },
    resetPasswordHandler() {
      this.resetPassword = true;
      this.resetPasswordPopup = true;
      // document.getElementById("resetPasswordContainer").scrollIntoView();
    },
    showProfileApprovalView() {
      let loggedInUserType =
        this.$store.state.AppActiveUser.userType === "superAdmin"
          ? "super-admin"
          : "admin";
      this.$router.push(
        `/${loggedInUserType}/nurses/${this.$route.params.nurseId}/profile-update-review`
      );
    },
    place_changed(a) {},
    async changeStatus(status) {
      this.statusChangedClick = true;
      let us = "Active";
      if (!status) us = "Disabled";

      let updateData = {
        status: us,
        userId: this.nurseData.id,
        updater: this.updater.firstName + " " + this.updater.lastName,
        name:  this.nurseData.firstName + " " + this.nurseData.lastName,
        updatedFields: {
          status: {
            oldValue: us ==='Disabled' ? 'Active' : 'Disabled', newValue: us
          }
        }
      };

      // if (us === "Active" && !this.nurseData.emailVerifiedAt) {
      //   updateData.emailVerifiedAt = new Date().toISOString();
      // }

      await this.updateNurseDetails({
        id: this.nurseId,
        data: updateData,
      })
        .then((res) => {
          // this.nurseData.emailVerifiedAt = res.data.data.emailVerifiedAt;
          this.$vs.notify({
            title: "Success",
            text: "Status updated successfully.",
            color: "success",
          });
          this.statusChangedClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update status. Try again!",
            color: "danger",
          });
          this.statusChangedClick = false;
        });
    },
    async changeAdminApproval(status) {
      this.adminApprovalChangeClick = true;

      let updateData = {
        hasApprovedByAdmin: !status,
        userId: this.nurseData.id,
        updater: this.updater.firstName + " " + this.updater.lastName,
        name:  this.nurseData.firstName + " " + this.nurseData.lastName,
        updatedFields: {
          hasApprovedByAdmin: {
            oldValue: status, newValue: !status
          }
        }
      };

      await this.updateNurseDetails({
        id: this.nurseId,
        data: updateData,
      })
        .then((res) => {
          this.$vs.notify({
            title: "Success",
            text: "Admin approval updated successfully.",
            color: "success",
          });
          this.adminApprovalChangeClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update admin approval. Try again!",
            color: "danger",
          });
          this.adminApprovalChangeClick = false;
        });
    },
    async getAccountLogs() {
      this.$refs.logsSidebar.openSidebar();

      await this.getAccountCreationLogs({
        id: this.$route.params.nurseId, 
        type: 'user'
      })
        .then(res => {
         this.activityLogs = res

        }).catch(err => {
          console.warn(err)
        })
      }
  },
  async created() {
    this.updater = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}User`)
    );

    this.activeUserType = this.$store.state.AppActiveUser.userType;
    let nurseId = this.$route.params.nurseId;
    this.nurseId = nurseId;
    await this.getNurseDetail(nurseId);

    await this.fetchClinics({ type: this.fetchClinicsBy }).then((res) => {
      if (this.fetchClinicsBy === "franchise") {
        this.clinics = res.data.data;
      } else {
        this.clinic = res.data.data.clinicId;
      }
    });

    this.isRequired =
      this.$store.state.AppActiveUser.userType === "superAdmin" ||
      this.$store.state.AppActiveUser.userType === "admin" ||
      this.$store.state.AppActiveUser.userType === "franchise" ||
      this.$store.state.AppActiveUser.userType === "subFranchise"
        ? false
        : true;
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
  },
};
</script>
<style lang="scss">
  .disabled-upload {
    display: none;
  }
  .con-img-upload {
    height: 50%;
  }
  .is-danger {
    border: 1px solid #ea5455 !important;
  }
  .is-true {
    border: 1px solid #28c76f !important;
  }
  .bg-color-done {
    background: #d4f4e2;
    color: #28c76f !important;
    border: 1px solid #28c76f !important;
    border-left: hidden !important;
  }

  .bg-color-error {
    background: #fbdddd;
    color: #ea5455 !important;
    border: 1px solid #ea5455 !important;
    border-left: hidden !important;
  }

  .custom-calendar .vdp-datepicker__calendar {
    bottom: 100% !important;
  }

  .vs-tabs--content {
    padding: 0;
  }
  .vs-tabs--ul {
    box-shadow: none;
  }
  .con-ul-tabs {
    overflow: auto;
    padding-bottom: 0.4rem;
  }
  .con-vs-tabs {
    overflow: visible;
  }
  .con-vs-tabs .con-slot-tabs{
    overflow: visible;
    margin-top: 1rem;
  }
  .doctorDetails .vs-card--content {
    padding: 20px;
  }
  .doctorDetails .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
      padding: 0.75rem 0.5rem;
  }
  .doctorDetails .vs-input--label {
      display: block;
      margin-bottom: 0.3rem;
  }
  .doctorDetails .vs-input--input,
  .doctorDetails .vs-select--input,
  .doctorDetails .vdp-datepicker input {
    font-family: inherit;
  }
  .doctorDetails .vs-select--input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 9px 10px;
  }
  .doctorDetails .mapboxgl-ctrl-geocoder {
    width: 100%!important;
    max-width: unset!important;
    box-shadow: none;
  }
  .doctorDetails  .mapboxgl-ctrl-geocoder input {
    padding: 8px 10px 8px 30px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 1rem;
    height: 40px;
   }
  .doctorDetails .mapboxgl-ctrl-geocoder--icon-search {
    top: 10px;
    width: 20px;
    height: 20px;
  }
  .doctorDetails .mapboxgl-ctrl-geocoder--icon {
    fill: #cfcfcf;
  }
  .doctorDetails .mapboxgl-ctrl-geocoder .suggestions{
    z-index: 10;
    position: relative;
  }

  @media screen and (min-width: 992px) {
    .doctorDetails .mainHeader .vs-card--header {
      padding: 5px;
    }
  }

.sidebarx {
  .vs-sidebar {
    max-width: 400px;

    &--items {
      overflow-y: auto;
      overflow-x: hidden !important;
    }
  }
}
</style>
